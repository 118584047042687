.Dashboard
  font-family: 'cera-pro'
  height: 100vh

  .ingredient-detail-image
    border-radius: 50%

  .og-logo
    position: absolute
    right: 0
    bottom: 0

  .initial-setup
    background-color: $secondary
    height: 100%
