.h1
  font-size: 42px
  font-weight: 900 !important
.h2
  font-weight: 900 !important
  font-size: 37px
.h3
  font-weight: 900 !important
  font-size: 30px
.h
  font-weight: 900 !important
.h5
  font-weight: 600 !important
  line-height: 1.5
.h6
  font-weight: 500 !important
