.feedback
  color: $c-anthrazit
  background-image: url('../../assets/images/feedback/clouds.svg')
  background-size: contain
  background-repeat: no-repeat
  height: 100%

  &__submitted
    pointer-events: none

  &__padding
    padding: 2rem

  &__header
    gap: 2rem
    @include media-breakpoint-down(md)
      gap: 0.5rem
    &__logo
      height: 5rem

  &__bottom
    &_button
      margin-top: 5rem
      margin-bottom: 6rem
      border-radius: 0.6rem
      padding: 0.5rem 2rem
      font-size: 1.875rem
      @include media-breakpoint-down(sm)
        margin-top: 5rem
        margin-bottom: 6rem
        border-radius: 0.6rem
        padding: 0.5rem 2rem
        font-size: 1.25rem

    &__rating-container
      margin-top: 10rem
      @include media-breakpoint-down(md)
        margin-top: 5rem
        width: 90% !important

  &__counter
    &_img
      width: 100%

    &__name
      color: $c-anthrazit

    &_button
      gap: 1rem

  &__stars
    margin-bottom: 2.063rem

    &__stars-container
      margin: 2.063rem 0
      gap: 3.344rem
      @include media-breakpoint-down(md)
        gap: 1.5rem
      @include media-breakpoint-down(sm)
        gap: 0.1rem

      &__star
        width: 3.688rem
        @include media-breakpoint-down(sm)
          width: 1.8rem

  &__reasons
    &__header
      margin-bottom: 4.563rem

    &__reasons-container
      gap: 2rem

    &__reason
      gap: 1rem

      &__icon
        width: 2.188rem
        @include media-breakpoint-down(sm)
          width: 1.5rem

      &__info
        cursor: pointer

  &__thanking-container
    left: 0
    top: 0

    &__heading
      font-size: 4.688rem

  &__back
    &_icon-container
      left: 0

    &_icon
      width: 3.5rem
      margin-left: 2rem
      @include media-breakpoint-down(md)
        width: 2rem
        margin-left: 0rem

  &__progress
    margin-top: 6.688rem
    margin-bottom: 6.688rem
    @include media-breakpoint-down(md)
      margin-top: 4rem
      margin-bottom: 6.688rem
      gap: 1rem

    &__bar
      width: 80%
      padding-left: 20
      @include media-breakpoint-down(sm)
        width: 90%

  &__dish-ranking
    gap: 3rem
    width: 100%

  &__dish
    gap: 5.875rem
    @include media-breakpoint-down(md)
      gap: 3rem
    @include media-breakpoint-down(sm)
      gap: 0.8rem

    &_image
      height: 100%
      width: 100%
      object-fit: cover

      &__container
        width: 8.875rem
        height: 8.875rem
        overflow: hidden
        position: relative
        @include media-breakpoint-down(sm)
          height: 4rem
          width: 4rem

    &__rating
      gap: 1rem
      width: 100%
      @include media-breakpoint-up(md)
        gap: 3rem

    &__star
      gap: 1rem
      @include media-breakpoint-down(md)
        gap: 0.5rem

  &_h1
    font-size: 4.375rem // TODO: Unused, remove it maybe
    @include media-breakpoint-down(md)
      font-size: 3rem !important
    @include media-breakpoint-down(sm)
      font-size: 2rem !important

  &_h2
    font-size: 3.438rem !important
    @include media-breakpoint-down(md)
      font-size: 2.5rem !important
    @include media-breakpoint-down(sm)
      font-size: 1.6rem !important

  &_h3
    font-size: 2.5rem !important
    @include media-breakpoint-down(md)
      font-size: 2rem !important
    @include media-breakpoint-down(sm)
      font-size: 1.2rem !important

  &_h4
    font-size: 2.188rem !important
    @include media-breakpoint-down(md)
      font-size: 1.5rem !important
    @include media-breakpoint-down(sm)
      font-size: 1rem !important

  &_h5
    font-size: 1.875rem !important
    @include media-breakpoint-down(md)
      font-size: 1.25rem !important
    @include media-breakpoint-down(sm)
      font-size: 0.8rem !important

  &_h6
    font-size: 1.563rem !important
    @include media-breakpoint-down(md)
      font-size: 1rem !important
    @include media-breakpoint-down(sm)
      font-size: 0.6rem !important

.hide
  display: none !important

.dish
  word-break: break-word
