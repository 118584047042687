.melf-chapter
  background-image: url('../../assets/images/melf_templates/shape_blue.png')
  background-position: -12vw -2vh
  background-size: 100% auto
  background-repeat: no-repeat
  color: #434343

  &.melf-green
    background-image: url('../../assets/images/melf_templates/shape_green.png')
  &.melf-yellow
    background-image: url('../../assets/images/melf_templates/shape_yellow.png')
  &.melf-brown
    background-image: url('../../assets/images/melf_templates/shape_brown.png')
  &.melf-blue
    background-image: url('../../assets/images/melf_templates/shape_blue.png')

  .type-icon
    width: 1em
    vertical-align: baseline

  .oglogo
    position: absolute
    top: 60px
    right: 60px
    width: 120px

  .counter-name
    font-family: 'cera-pro-black'
    font-weight: 500

  .healthy-facts
    position: absolute
    right: 0
    bottom: 6rem
    border: 2px solid $c-grasgrun
    max-width: 10%

    .healthybenefit__image
      width: 75%

    .healthybenefit__title
      text-transform: none !important
      font-size: inherit !important

  .seal
    display: inline-block
    background-position: center
    background-size: contain
    background-repeat: no-repeat
    width: 6rem
    height: 6rem

.melf-footer
