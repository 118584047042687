.imagemask
  height: 100vH
  padding: 0
  &__image
    height: 100%
    object-fit: cover
    position: absolute
    display: block
    left: 0%
  &__mask
    mask: url(/assets/images/shape-1.svg)
    mask-repeat: no-repeat
    mask-position: 0%
    mask-size: 150%
    height: 100%
    width: 100%
  &--left
    .imagemask
      &__image
        left: auto
        right: 0%
      &__mask
        mask: url(/assets/images/shape-2.svg)
        mask-position: 100% 40%
        mask-size: 150%

.fixed-image
  &__wrapper
    position: absolute
    z-index: 50
    left: 10%
    top: 10%
  &--centered
    left: 42%
    top: 25%
  &__image
    width: 270px
    height: 270px
    object-fit: cover
    &--circle
      border-radius: 50%

.footer
  &__oglogo
    position: absolute
    right: 0
    bottom: 0
    z-index: 100

  &__partnerlogo
    position: absolute
    right: 210px
    bottom: 30px
    width: 125px
    height: 125px
    object-fit: cover
  .disclaimer
    position: absolute
    left: 47%
    width: 40%
    bottom: 15px
