@import '../../styles/mixins/_schoolheading'

.weeklymenuschoolchapter
  color: #424241
  background-image: url('../../assets/images/school_templates/clouds_bottom.svg'), url('../../assets/images/school_templates/clouds_top.svg')
  background-position: -90% -1290%, 180% 140%
  background-repeat: no-repeat, no-repeat

  &__main-dish-container
    height: 15.625rem

  h1
    @include school-heading
    