@import '../../styles/mixins/_schoolheading'

.schooldisheschapter
  color: #424241

  &__logo
    right: 0
    top: 0

  &__dishes
    min-height: 22rem

  &__dish-container
    background-color: #dceeeb

  &__badge
    padding: 0.5rem 1.4rem
    border-radius: 0.7rem

    &__vegan
      background-color: #88ac3f 

    &__side
      background-color: #575e95

    &__dessert
      background-color: #f5b843
    
    &_container
      height: 2.5rem
  
  &__main-dish
    height: 7.1875rem

  h1
    @include school-heading

  h2
    font-size: 2.3vw
    font-size: clamp(0.5rem,2.3vw,10rem)

  &__healthy-benefit
    &_img
      width: 8rem

  &__footer
    &_icon 
      width: 25px