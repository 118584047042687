$c-redcoral: #E9443c
$c-tourkouis-100: #8AC7BC
$c-tourkouis-90: #acdfd5
$c-tourkouis-80: #bfd8d4
$c-beige: #E2CDB4
$c-braun: #af866e
$c-blaugrau: #6B888E
$c-blaugrau-90: #87a7aa
$c-anthrazit: #414141
$c-anthrazit-90: #686868
$c-anthrazit-80: #9e9e9e
$c-grasgrun: #90ab48
$c-blassgrun: #a7b395
$c-blassgrun-90: #acb3a3
$c-blassgrun-80: #b5b8b2
$c-lila: #575795
$c-sonnengelb: #F7B700


